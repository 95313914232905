@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100dvh;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100dvh;
  width: 100vw;
}

.bg-crosses {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2233%22%20height=%2233%22%20viewBox=%220%200%2033%2033%22%3E%3Cpath%20d=%22M0%200%20L2.25%200Z%20M0%200%20L0%202.25Z%20%20M33%2033%20L30.75%2033Z%20M33%2033%20L33%2030.75Z%20%20M33%200%20L30.75%200Z%20M33%200%20L33%202.25Z%20%20M0%2033%20L0%2030.75Z%20M0%2033%20L2.25%2033Z%22%20stroke-opacity=%220.065%22%20stroke-width=%221.1%22%20stroke=%22black%22%20stroke-linecap=%22butt%22%3E%3C/path%3E%3C/svg%3E");
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinning-bg {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vh;
  height: 100vh;
  z-index: -1;
  opacity: 10%;

  transform: translateY(-500px);
  border-radius: inherit;
  /* Match parent's border radius */
  animation: spin 10s linear infinite;
}

@keyframes spin2 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

.spinning-bg2 {
  content: "";
  display: block;
  position: absolute;
  opacity: 10%;
  top: 0;
  left: 0;
  width: 100vh;
  height: 100vh;
  z-index: -1;
  transform: translateY(-500px);
  border-radius: inherit;
  /* Match parent's border radius */
  animation: spin2 10s linear infinite;
}

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

.bubble::after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: -0px;
  width: 38px;
  height: 26px;
  background: rgb(59 130 246);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  box-shadow: 0 2px 4px 0 gainsboro;
}

.bubble-white::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: -0px;
  width: 24px;
  height: 12px;
  background: rgb(59 130 246);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  box-shadow: 0 2px 4px 0 gainsboro;
}