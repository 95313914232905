body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.strand {
    position: relative;
    width: 15px;
    height: 40px;
    animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.top,
.bottom {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 12px;
    width: 12px;
    border-radius: 100%;
    animation: 3s move infinite ease-in-out;
}

.middle-container {
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: col;
    justify-content: center;
    align-items: center;
}

.middle {
    position: relative;
    left: 42%;
    height: 42px;
    width: 8px;
    border-radius: 20px;
    background-color: #6e3af0;
    animation: 3s rotate infinite ease-in-out;
}

@keyframes rotate {
    0% {
        height: 42px;
        z-index: -5;
        background-color: #6e3af0;
    }

    25% {
        height: 12px;
        background-color: #983af0;
    }

    50% {
        height: 42px;
        z-index: -5;
        background-color: #6e3af0;
    }

    75% {
        height: 12px;
        background-color: #983af0;
    }

    100% {
        height: 42px;
        z-index: -5;
        background-color: #6e3af0;
    }
}

@keyframes move {
    0% {
        background-color: #bca0dc;
        top: 100%;
        z-index: -3;
    }

    25% {
        height: 8px;
        width: 8px;
        background-color: #a868e0;
        z-index: -3;
    }

    50% {
        background-color: #bca0dc;
        top: 0%;
        z-index: -3;
    }

    75% {
        height: 12px;
        width: 12px;
        background-color: #bca0dc;
        z-index: 3;
    }

    100% {
        top: 100%;
        background-color: #bca0dc;
        z-index: 3;
    }
}